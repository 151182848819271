import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/NotFindView.vue'),
  },
  {
    path: '/:partnerId/',
    name: 'homeWithPartner',
    props: (to) => ({
      partnerId: +to.params.partnerId,
    }),
    component: () => import('../views/HomeView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/NotFindView.vue'),
  },
  {
    path: '/:partnerId/login',
    name: 'loginWithPartner',
    props: (to) => ({
      partnerId: +to.params.partnerId,
    }),
    component: () => import('@/views/AuthorizationView.vue'),
  },
  {
    path: '/loans',
    name: 'loans',
    component: () => import('@/views/NotFindView.vue'),
  },
  {
    path: '/:partnerId/loans',
    name: 'loansWithPartner',
    props: (to) => ({
      partnerId: +to.params.partnerId,
    }),
    component: () => import('@/views/LoansView.vue'),
  },
  {
    path: '/managers',
    name: 'managers',
    component: () => import('@/views/NotFindView.vue'),
  },
  {
    path: '/:partnerId/managers',
    name: 'managersWithPartner',
    props: (to) => ({
      partnerId: +to.params.partnerId,
    }),
    component: () => import('@/views/ManagersView.vue'),
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/NotFindView.vue'),
  },
  {
    path: '/:partnerId/reports',
    name: 'reportsWithPartner',
    props: (to) => ({
      partnerId: +to.params.partnerId,
    }),
    component: () => import('@/views/ReportsView.vue'),
  },
  {
    path: '/:partnerId/new_loan',
    name: 'NewLoanWithPartner',
    props: (to) => ({
      partnerId: +to.params.partnerId,
    }),
    component: () => import('@/views/LoanView.vue'),
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('@/views/NotFindView.vue'),
  },
  {
    path: '/:partnerId/help',
    name: 'helpWithPartner',
    props: (to) => ({
      partnerId: +to.params.partnerId,
    }),
    component: () => import('@/views/HelpView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
